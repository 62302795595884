.addTask{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  z-index: 100;
}

.addTask input{
  height: 40px;
  border: unset;
  font-size: 18px;
  box-sizing: border-box;
  -webkit-box-sizing:border-box;
  -moz-box-sizing: border-box;
  border-bottom: 1.5px solid hsl(210, 10%, 75%);
  padding-left: 5px;
}

.addTask input:focus{
  outline: none;
}

.addTask button {
  height: 40px;
  border: none;
  color: hsl(210, 12%, 15%);
  background-color: #bd5500;
}

.addTask textarea {
  height: 150px;
  border-color: hsl(210, 10%, 75%);
  padding: 5px;
  font-size: 18px;
}

.addTask textarea:focus { 
  outline: none;
}

.addTask button:hover {
  background-color: #95633b;
  color: white;
}

.addTask button:active {
  background-color: hsl(210, 12%, 15%);
}

.editTask{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  z-index: 100;
}

.editTask input{
  height: 40px;
  border: unset;
  font-size: 18px;
  box-sizing: border-box;
  -webkit-box-sizing:border-box;
  -moz-box-sizing: border-box;
  border-bottom: 1.5px solid hsl(210, 10%, 75%);
  padding-left: 5px;
}

.editTask input:focus{
  outline: none;
}

.editTask button {
  height: 40px;
  border: none;
  color: white;
  background-color: hsl(210, 12%, 15%);
}

.editTask textarea {
  height: 150px;
  border-color: hsl(210, 10%, 75%);
  padding: 5px;
  font-size: 18px;
}

.editTask textarea:focus { 
  outline: none;
}

.editTask button:hover {
  background-color: hsl(210, 12%, 25%);
}

.editTask button:active {
  background-color: hsl(210, 12%, 15%);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: hsl(210, 12%, 95%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin: 0;
  padding: 0;
}

button{
  cursor: pointer;
}

.task{
  width: 100%;
  padding: 15px 20px;
  border-left: 4px solid #bd5500;
  background-color: white;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  align-items: center;
  box-sizing: border-box;
}

.task--borderColor{
  border-color: green;
  box-shadow: 0 6px 18px -9px hsl(210, 15%, 65%);
}

.task__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.task__body p{
  margin: 10px 0 0 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; 
}

.task__buttons{
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  align-items: center;
}

.task__buttons > button {
  background-color: hsl(210, 70%, 60%);
  padding: 5px 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  color: white;
}

.task__buttons > button:hover {
  background-color: hsl(210, 70%, 50%);
}

.task__buttons > button:active{
  background-color: hsl(210, 70%, 60%);
}

.task__deleteNedit button {
  margin-right: 20px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: hsl(210, 10%, 55%);
}

.task__editButton:hover {
  color: hsl(210, 20%, 25%);
}

.task__editButton:active {
  color: hsl(210, 10%, 55%);
}

.task__deleteNedit .task__deleteButton {
  color: hsl(0, 80%, 80%);
}

.task__deleteButton:hover {
  color: hsl(0, 70%, 70%);
}

.task__deleteButton:active {
  color: hsl(0, 80%, 80%);
}

.taskItem{
  margin-top: 20px;
}

.taskItem h2{
  font-weight: 400;
  text-transform: uppercase;
}

.taskItem p{
  margin-top: 10px;
  font-size: 18px;
  max-height: 300px;
  line-height: 25px;
  overflow-y: auto;
  color: hsl(210, 20%, 25%);
}

.taskManager > header{
  padding: 25px 20px;
  background-color: #95633b;
  color: white;
  font-size: 28px;
  font-family: 'Hanalei Fill', cursive;
}

.taskManager__container {
  width: 95%;
  margin: 50px auto 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.taskManager__container > button {
  border-radius: 5px;
  border: none;
  color: white;
  font-size: 18px;
  padding: 10px 15px;
  margin: 0 auto;
  background-color: #bd5500;
  border: solid 1px black;
  cursor: pointer;
}

.taskManager__container > button:hover{
  background-color: #95633b;
  border: solid 1px white;
}

.taskManager__container > button:active{
  background-color: hsl(210, 12%, 15%);
}

.taskManager__tasks{
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}